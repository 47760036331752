@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .barlow-thin {
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .barlow-thin-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: italic;
  }

  .barlow-extralight {
    font-family: "Barlow", sans-serif;
    font-weight: 200;
    font-style: normal;
  }

  .barlow-extralight-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 200;
    font-style: italic;
  }

  .barlow-light {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  .barlow-light-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-style: italic;
  }

  .barlow-regular {
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  .barlow-regular-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: italic;
  }

  .barlow-medium {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

  .barlow-medium-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: italic;
  }

  .barlow-semibold {
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .barlow-semibold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 600;
    font-style: italic;
  }

  .barlow-bold {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .barlow-bold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-style: italic;
  }

  .barlow-extrabold {
    font-family: "Barlow", sans-serif;
    font-weight: 800;
    font-style: normal;
  }

  .barlow-extrabold-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 800;
    font-style: italic;
  }

  .barlow-black {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  .barlow-black-italic {
    font-family: "Barlow", sans-serif;
    font-weight: 900;
    font-style: italic;
  }

  html {
    font-family: "Barlow";
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  background-color: #ffffff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
